import request from "./server";
import { PORT1 } from "../config/servicePort";

// 意见反馈类型
export const opinionType = (data) => {
    return request.get(PORT1 + `/opinion/types`, data);
};
// 意见反馈
export const opinion = (data) => {
    return request.post(PORT1 + `/opinion/create`, data);
};
// 私信
export const messageList = (data) => {
    return request.get(PORT1 + `/message/private`, data);
};
// 广播
export const messageBroadcast = (data) => {
    return request.get(PORT1 + `/message/broadcast`, data);
};
// 删除通知
export const messageDel = (data) => {
    return request.put(PORT1 + `/message/deletes`, data,true);
};
// 已读通知
export const messageReads = (data) => {
    return request.put(PORT1 + `/message/reads`, data);
};
// 充值接口列表
export const rechargeList = (data) => {
    return request.get(PORT1 + `/recharge/index`, data);
};
// 充值活动列表
export const rechargeActivity = (data) => {
    return request.get(PORT1 + `/activity/recharge_index`, data);
};
// 发起支付
export const sendPay = (data) => {
    return request.post(PORT1 + `/recharge/send_pay`, data,true);
};
// 完成支付
export const voucher = (data) => {
    return request.post(PORT1 + `/recharge/voucher`, data,true);
};
// 取消支付
export const rechargeCancel = (data) => {
    return request.get(PORT1 + `/recharge/cancel`, data,true);
};
// 提现列表
export const withdrawList = (data) => {
    return request.get(PORT1 + `/withdraw/index`, data);
};
// 发起提款
export const sendWithdraw = (data) => {
    return request.post(PORT1 + `/withdraw/send_withdraw`, data,true);
};
// 帐变记录
export const tradeDetail = (data) => {
    return request.get(PORT1 + `/user/trade_detail`, data);
};

// 全部帐变记录
export const balanceList = (data) => {
    return request.get(PORT1 + `/balance/list`, data);
};
// 全部帐变类型
export const balanceType = (data) => {
    return request.get(PORT1 + `/balance/type`, data);
};
// 银行列表
export const bankList = (data) => {
    return request.get(PORT1 + `/bank/index`, data,true);
};
// 银行列表
export const myBankList = (data) => {
    return request.get(PORT1 + `/bank/list`, data);
};
// 绑定银行卡或虚拟币
export const bankCreate = (data) => {
    return request.post(PORT1 + `/bank/create`, data, true);
};
// 我的vip
export const vipMe = (data) => {
    return request.get(PORT1 + `/vip/me`, data);
};
// 
export const vipPrivilege = (data) => {
    return request.get(PORT1 + `/vip/privilege`, data);
};
// 领取每周红包
export const vipWeekly = (data) => {
    return request.post(PORT1 + `/vip/weekly`, data);
};
// 修改用户昵称
export const nicknameUser = (data) => {
    return request.put(PORT1 + `/user/nickname`, data,true);
};
// 获取默认头像
export const avatarsUser = (data) => {
    return request.get(PORT1 + `/user/avatars`, data);
};
// 修改默认头像
export const updateAvatar = (data) => {
    return request.put(PORT1 + `/user/update_avatar`, data);
};
// 会员福利
export const welfareList = (data) => {
    return request.get(PORT1 + `/welfare/list`, data);
};
// 领取会员福利
export const welfareReceive = (data) => {
    return request.put(PORT1 + `/welfare/receive`, data);
};
// 领取每周福利
export const welfareVipWeekly = (data) => {
    return request.post(PORT1 + `/vip/weekly`, data);
};
// 领取生日礼金
export const welfareVipBirthday = (data) => {
    return request.post(PORT1 + `/vip/birthday`, data);
};
// 福利类型
export const welfareTypes = (data) => {
    return request.get(PORT1 + `/welfare/types`, data);
};
// 反水列表（回扣收入）
export const waterList = (data) => {
    return request.get(PORT1 + `/user/water_list`, data);
};
// 手动返回
export const manualWater = (data) => {
    return request.post(PORT1 + `/user/manual_water`, data,true);
};
// 手动反水列表
export const manualWateGet = (data) => {
    return request.get(PORT1 + `/user/manual_water`, data,true);
};
// 充值数量提示
export const rechargeNotOperated = (data) => {
    return request.get(PORT1 + `/recharge/not_operated`, data,true);
};
// 第三方召回余额e
export const transferOutAll = (data) => {
    return request.post(PORT1 + `/otherGame/transferOutAll`, data,true);
};
// 救助基金
export const rescueList = (data) => {
    return request.get(PORT1 + `/rescue/list`, data);
};
// 救助基金列表
export const rescueReceiveList = (data) => {
    return request.get(PORT1 + `/rescue/receive_list`, data);
};
// 救助基金领取
export const rescueReceive = (data) => {
    return request.post(PORT1 + `/rescue/receive`, data,true);
};
// 票券列表
export const ticketingList = (data) => {
    return request.get(PORT1 + `/ticketing/list`, data);
};
// 领取票券记录
export const ticketingRecord = (data) => {
    return request.get(PORT1 + `/ticketing/record`, data);
};
// 票券类型
export const ticketingClassList = (data) => {
    return request.get(PORT1 + `/ticketing/classList`, data);
};
// 充值详情
export const rechargeCallbackPay = (data) => {
    return request.get(PORT1 + `/recharge/callback_pay_detail`, data);
};







