
import { createI18n } from 'vue-i18n';
import { Locale } from 'vant';

import zhCN from './locales/zh-cn';
import zhCnLocale from "vant/es/locale/lang/zh-CN";
import enUS from './locales/en_us'

import enUsLocale from "vant/es/locale/lang/en-US";
import filPHP from './locales/fil_ph'
const messages = {
  'zh-CN': {
    ...zhCN,
    ...zhCnLocale
  },
  'en-US': {
    ...enUS,
    ...enUsLocale
  },  
  'fil': {
    ...filPHP,
    ...enUsLocale
  }, 
}

function GetQueryString(name)
{
     var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
     var r = window.location.search.substr(1).match(reg);
     if(r!=null)return  unescape(r[2]); return null;
}
function vantLocales (lang) {
  if (lang == 'zh-CN') {
    Locale.use(lang, zhCnLocale)
  } else if (lang == 'en-US') {
    Locale.use(lang, enUsLocale)
  }else{
    Locale.use(lang, enUsLocale)
  }
}
const language = (navigator.language || 'en-US') // 这是获取浏览器的语言
const languageNum = GetQueryString('lang') ;
vantLocales(languageNum || 'en-US');
const i18n = createI18n({
  locale: languageNum || language || 'en-US', // 首先从缓存里拿，没有的话就用浏览器语言，
  fallbackLocale: 'en-US', // 设置备用语言
  messages, 
  legacy: false, //不然会报错Uncaught SyntaxError: Not available in legacy mode、
  silentFallbackWarn: true,
})

export {i18n, vantLocales}

