
import axios from "axios";
import router from '@/router/index';
import loading from '@/components/mode/loading';
import oneLoad from '@/components/mode/oneLoad';
import { dropOut } from '@/utils/config/disposition';
import { showToast } from 'vant';
function GetQueryString(name)
{
     var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
     var r = window.location.search.substr(1).match(reg);
     if(r!=null)return  unescape(r[2]); return null;
}
const languageNum = localStorage.getItem('lang') || GetQueryString('lang') || navigator.language;
console.log(languageNum );
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 20 * 1000, // 请求超时时间
    headers: { "Content-Type": "application/json;charset=UTF-8" }
}); 
//请求拦截
service.interceptors.request.use((config) => {
    // 这里可以设置token: config!.headers!.Authorization = token
    let token = localStorage.getItem('Authorization');
    if (config.url == 'upload/image') {
        var contentType = 'multipart/form-data'
    } else {
        var contentType = 'application/x-www-form-urlencoded'
    }
    config.headers = {
        'Content-Type': contentType,
        'Authorization': token,
        'apikey': "9264586_jfwHw)))-w1",
        'Accept-language': `${languageNum},zh;q=0.9`,
    };
    return config;
});
//响应拦截
service.interceptors.response.use((response) => {
    const data = response.data;
    return data;
},
(err) => {
        if(err.code == "ECONNABORTED") {
            showToast('ECONNABORTED');
            return Promise.reject(false);
        } else {
            const { response } = err;
            const data = response.data;
            if(err.response.data.status == 70003 || err.response.data.status == 70006){
                dropOut();
                router.push({
                    name:'login',
                })
            }
            if(data.status == 40000) {
                return response;
            }else {
                return Promise.reject(false);
            }
        }
    }
);
// 请求方式
const request = {
    get(url, data,loadings = false) {
        return request.request("GET", url, { params: data },loadings);
    },
    post(url, data,loadings = false) {
        return request.request("POST", url, { data },loadings);
    },
    put(url, data,loadings = false) {
        return request.request("PUT", url, { data },loadings);
    },
    delete(url, data,loadings = false) {
        return request.request("DELETE", url, { params: data },loadings);
    },
    request(method = "GET", url, data,loadings) {
        return new Promise((resolve, reject) => {
            // if(!localStorage.getItem("Device")) {
            //     if(url == '/api/v1/user/detail') {
            //         oneLoad();
            //     }
            // }
       
            if(loadings) {
                loading()//请求开始
            }
            service({ method, url, ...data })
                .then((res) => {
                    // if(!localStorage.getItem("Device")) {
                    //     if(url == '/api/v1/user/detail') {
                    //         oneLoad('close');
                    //     }
                    // }
               
                    if(loadings) {
                        loading('close')//请求结束
                    }
                    resolve(res);
                })
                .catch((e) => {
                    // if(!localStorage.getItem("Device")) {
                    //     if(url == '/api/v1/user/detail') {
                    //         oneLoad('close');
                    //     }
                    // }
                    if(loadings) {
                        loading('close')//请求结束
                    }
                    reject(e);
                })
        });
    }
};
export default request;


