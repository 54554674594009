export default {
  config:{
    "cancel":"取消",
    "confirm":"确认",
    "Today":"今天",
    "Yesterday":"昨天",
    "sixday":"7天内",
    "month":"30天",
    "SMS": "请输入短信验证码",
    "mobile":"请输入手机号",
    "loading":"加载中",
    "gameerror":"游戏报错",
    "取消收藏":"取消收藏",
    "收藏成功":"收藏成功",
    "uploadImages":"请上传正确格式图片",
    "充值":"充值",
    "全屏":"全屏",
    "success":'成功',
    "身份信息修改成功":"Identity information modified successfully",
    "添加成功":"添加成功",
    "全部":"全部",
    "热门":"热门",
    "最近":"最近",
    "收藏":"收藏",
    "请输入搜索关键词":"请输入搜索关键词",
    "logout":"您确定要退出登录吗?",
    "下属名称":"Subordinate name",
 },
  login: {
    "title": "登录",
    "Remember": "记住密码",
    "password": "忘记密码",
    "Register": "注册",
    "account": "没有账号",
    "userPlaceholder": "请输入账号",
    "passwordPlaceholder": "请输入密码",
    "tips": "请输入6-13个字母数字，不含特殊字符",
    "SMS": "请输入短信验证码",
    "mobile":"请输入手机号",
    "code":"请输入验证码",
    "Kind":"温馨提示",
    "first":"请先完成验证",
    "graphic":"请输入图形验证码",
    "Send":"发送验证码",
  },
  register: {
    "title": "注册",
    "Reset": "重置",
    "userPlaceholder": "请输入账号",
    "passwordPlaceholder": "请输入密码",
    "confirmPlaceholder": "请再次输入密码",
    "phonePlaceholder": "请输入手机号",
    "tips": "请输入6-13个字母数字，不含特殊字符",
    "tipsTow": "请输入用户名和密码",
    "tipsTows": "两次密码不匹配",
    "tipsThree": "请输入10 - 11个字符",
    "empty":"内容不能为空",
    "invitation":"请输入邀请码",
  },
  forget:{
    "Forgot":"忘记密码",
    "following":"请通过以下方式找回您的密码。",
    "newpassword":"请输入新密码",
    "passwordagain":"请再次输入新密码",
    "wait":"重置密码中，请稍后",
  },
  member: {
    "head": "我的账户",
    "center": "会员中心",
    "Reward": "奖励中心",
    "Betting": "投注记录",
    "Profit": "收益与损失",
    "Deposit": "存款记录",
    "Withdrawal": "提款记录",
    "Account": "账户记录",
    "MyAccount": "我的账户",
    "Security": "安全中心",
    "Invite": "我的邀请",
    "Manual": "手动返利",
    "Rebate": "我的回扣",
    "Internal": "我的通知",
    "Suggestion": "意见反馈",
    "Service": "客服中心",
    "Signin": "签到",
    "Nickname": "昵称",
    "DepositH": "充值",
    "Withdrawals": "提现",
    "Cards": "银行卡",
    "Benefits": "福利",
    "Overview":"概述",
    "Registration":"登记",
    "TeamManagement":"团队管理",
    "TeamBetting":"团队投注",
    "TeamPL":"团队盈亏",
    "Announcement":"公告",
    "anage":"代理中心"
  },
  feedback: {
    "title": "反馈类型",
    "type": "请选择问题类型",
    "contentTite": "还有什么可以改进的吗？",
    "content": "请输入内容",
    "upload": "上传",
    "code": "验证码",
    "submit": "提交",
    "select":"请选择问题类型"
  },
  addBankCard:{
    "title":"添加银行卡",
    "card":"请填写银行卡卡号",
    "name":"请填写收款人姓名",
    "phone":"请填写手机号",
    "code":"请填写验证码",
    "tips":"请确保姓名与银行卡相符，以免提现失败。",
    "submit":"提交",
    "select":"请选择银行",
    "address":"请填写支行信息",
    "password":"请填写交易密码",
  },
  addBankCardPix:{
    "title":"添加电子钱包",
    "group":"选择电子钱包组",
    "type":"电子钱包类型",
    "agreement":"协议",
    "submit":"提交",
    "account":"请填写账号",
    "address":"请填写支行信息",
    "virtual":"虚拟币",
    "E-wallet":"电子钱包",
  },
  webEmail:{
    "title":"私信",
    "Inbox":"收件箱",
    "selectAll":"全选",
    "Sender":"发件人",
    "tit":"标题",
    "Confirmation":"温馨提示",
    "delDes":"您确定要删除吗？",
    "message":"暂时没有私信",
    "Sender":"发件人",
    "titls":"标题",
    "Time":"发送时间",
  },
  notice:{
    "title":"公告",
  },
  voucherCenter:{
    "title":"充值中心",
    "deposit":"存款方式",
    "channels":"支付渠道",
    "amount":"存款金额",
    "virtual":"虚拟币协议",
    "Next":"下一步",
    "Minimum":"最低金额",
    "Maximum":"最高金额",
    "DepositInfo":"存款信息",
    "order":"已下订单",
    "account":"存款账户",
    "money":"存款金额",
    "copy":"复制",
    "tips":"提示",
    "connection":"如果您有任何疑问，请随时联系我们。",
    "TransactionRecord":"交易记录",
    "CompleteDeposit":"完成存款",
    "Another":"取消存款",
    "service":"联系客服",
    "noActivity":"不参加活动",
    "Activity":"活动",
    "depositorname":"请输入存款人姓名",
    "successPay":"成功！请前往存款页面支付",
    "protocol":"钱包协议",
    "cardName":"银行卡名称",
    "address":"钱包地址",
    "cardAddress":"银行卡账号",
    "virtual":"虚拟币数量",
    "Ordernumber":"订单号",
    "rate":"汇率",
    "period":"有效期",
    "tips1":"1. 付款前请确认费用，押金金额将根据收到的金额计算。",
    "tips2":"2. 请在30分钟内完成付款，预计存款时间约为3分钟至20分钟。如果您在期限内未收到款项，请提供您的交易ID、钱包地址和交易哈希，以便更快地处理，谢谢。",
  },
  activity:{
    "title":"活动中心",
  },
  myWithdrawCards:{
    "title":"我的银行卡",
    "wallet":"电子钱包已添加",
    "emptyWallet":"空的电子钱包",
    "bank":"银行账户",
    "emptyBank":"空的银行卡",
    "addnew":"新增",
    "walletName":"电子钱包",
  },
  benefits:{
    "title":"我的VIP",
    "Rules":"规则",
    "Next":"下一等级",
    "up":"上一等级",
    "Levels":"升级要求",
    "Upgrade":"升级",
    "Upgrade":"升级",
    "Fulfil":"完成所有任务即可升级",
    "Completed":"完成",
    "TotalBet":"总投注",
    "TotalDeposit":"总存款",
    "go":"去完成",
    "withdrawals":"每日提款次数",
    "quota":"每日提款额度",
    "UpgradesQuota":"升级礼金（普及手动领取）",
    "birthday":"生日礼金",
    "week":"每周礼金",
    
  },
  nickName: {
    "title":"修改昵称",
    "Submit":"修改",
    "Please":"请输入要修改的昵称",
    "complete":"修改昵称成功"
  },
  avatar:{
    "uploadImage":"点击图片上传您自己的头像",
    "Classic":"经典头像",
    "ok":"保存",
  },
  withdraw:{
    "title":"提现",
    "mybank":"我的银行卡",
    "bank":"银行卡",
    "banks":"空的银行卡",
    "walletss":"电子钱包",
    "walletsss":"空的钱包",
    "wallets":"我的电子钱包",
    "Account":"银行卡账号",
    "BindTiem":"绑定日期",
    "withdrawTiem":"提现时间",
    "Tips":"温馨提示",
    "other":"注意：请确保您的GCASH已通过提现申请验证。如果验证未完成，则不会记入账户",
    "Daily":"每日提现",
    "Remaining":"剩余提现",
    "Times":"次",
    "MainWallet":"主钱包",
    "type":"提现类型",
    "AvailableAmount":"可用金额",
    "balance":"召回余额",
    "Ok":"好的",
    "turnover":"提款周转要求",
    "completeTurnover":"请完成提款所需的营业额。",
    "GameType":"游戏类型",
    "RemainingRurnover":"剩余营业额",
    "General":"一般的",
    "amount":"金额",
    "mobile":"手机号",
    "verify_code":"验证码",
    "trade_password":"交易密码",
    "trade_password_p":"请输入交易密码",
    "submit":"提交",
    "BalanceSuccessful":"召回余额成功",
    "Theinputamountcannotbelessthan":"输入金额不能小于",
    "greater":"大于",
    "mobiles":"请输入10 到 11 位数的手机号",
    "card":"请添加银行卡",
    "money":"请输入提现金额",
    "mobileE":"手机号不能为空",
    "verify_codeE":"验证码不能为空",
    "order":"提现订单已提交"
  },
  myAccount:{
    "title":"我的账户",
    "username":"用户名",
    "privacy":"我们关心您的隐私",
    "protected":"所有用户数据都经过加密，以确保您的个人隐私受到保护。",
    "nickname":"请填写用户昵称",
    "email":"请填写邮箱号",
    "real_name":"请填写真实昵称",
    "mobile":"请填写手机号",
    "real_name_tips":"请确保姓名与提款信息相符",
    "email_tips":"请输入邮箱格式1-255个字符",
    "mobile_tips":"请输入10 - 11个字符",
    "Submit":"提交"
  },
  vouReport:{
    "title":"充值记录",
    "Deposit":"订单编号",
    "Postscript":"备注",
    "ReceivedTime":"收到时间",
    "fee":"手续费",
    "Activity":"活动",
    "Request":"支付金额",
    "Received":"收到金额",
    "Status":"状态",
    "type":"类型",
    "discount":"优惠金额",
    "待付款":"待付款",
    "付款待审核":"付款待审核",
    "已完成":"已完成",
    "已取消":"已取消",
    "挂单":"挂单",
    "拒绝":"拒绝",
  },
  withdrawReport:{
    "title":"提现记录",
    "Request":"提现金额",
  },
  gameRecord:{
    "title":"投注记录",
    "all":"全部",
    "order":"订单号",
    "BetAmount":"投注金额",
    "ValidBet":"有效投注",
    "ProfitAndLoss":"收益与损失",
    "Winnings":"盈利",
  },
  loginChange:{
    "title":"修改登录密码",
    "tipContent":"请输入6-12位字母数字，不含特殊字符",
    "Submit":"提交",
    "oldNewPassowrd":"两次密码不相同",
    "kong":"请输入内容",
    "oldPassword":"请输入当前密码",
    "newPassword":"请输入新的密码",
    "ConfirmPassword":"请再次输入新的密码",
    "Confirm":"修改登录密码成功",
  },
  FundsPasswordBox:{
    "title":"交易密码",
    "exit":"修改交易密码成功",
    "add":"添加交易密码成功",
  },
  securityCenter:{
    "title":"安全中心",
    "tips-up":"您的账号等级为",
    "tips-nx":"，请提升您的安全信息",
    "information":"个人信息",
    "informationTips":"完善个人信息",
    "loginPassword":"修改登录密码",
    "loginPasswordTips":"推荐的字母和数字组合",
    "password":"交易密码",
    "passwordTips":"设置交易密码，提高资金运作的安全性",
    "card":"银行卡账号",
    "cardTips":"请使用真实的银行账号",
    "wallet":"绑定电子钱包",
    "walletTips":"绑定电子钱包进行提现。",
    "login":"登出",
    "loginTips":"安全注销",
    "Safety":"安全等级",
    "LastIp":"上次登录IP",
    "lastTiem":"上次登录时间",
    "high":"高",
    "In":"中",
    "low":"低",
  },
  myBankCards:{
    "title":"银行帐号",
    "addBank":"添加银行账户",
    "Maximum":"最多添加",
    "allowed":"个",
    "Empty":"空的银行卡"
  },
  myEWallet:{
    "title":"绑定电子钱包",
    "addBank":"添加电子钱包",
    "Empty":"空的电子钱包",
  },
  profitandloss:{
    "title":"个人报告",
    "Total":"总盈亏",
    "Deposit":"充值",
    "Activity":"活动",
    "Income":"收入",
    "Withdrawal":"提款",
    "Expenses":"开支",
    "Betting":"投注",
    "ValidBet":"有效投资",
    "winAmount":"盈利金额",
    "Rebate":"回扣",
    "Bonus":"奖金",
  },
  home:{
    "hotGame":"热门游戏",
    "service":"客服",
    "cashBack":"现金返还",
    "Withdraw":"提现",
    "Bonus":"福利",
    "leaderBoard":"leaderBoard",
    "home":"首页",
    "agent":"代理",
    "activity":"活动",
    "member":"我的",
    "Confirmation":"确认",
    "logout":"您确认要退出吗？",
    "updates":"我们希望将其添加到您的主屏幕，以获取最新的免费投注和奖金更新。",
    "Continue":"继续",
    "benefits":"享受更多福利！",
    "Category":"Category",
    "Providers":"Providers",
    "OtherGames":"Other Games",
    "inviteFriends":"邀请"
  },
  record:{
    "title":"账户记录",
    "order":"订单编号",
    "Balance":"账户余额",
    "Transaction":"交易金额",
  },
  inviteFriends:{
    "title":"邀请好友",
    "Overview":"概述",
    "Incomes":"收入",
    "Records":"记录",
    "Invitee":"奖励",
    "TodayIncome":"今日收入",
    "YesterdayIncome":"昨日收入",
    "Registers":"邀请人数",
    "ValidInvitees":"有效邀请人",
    "URL":"邀请网站",
    "share":"分享给你的朋友",
    "copy":"分享链接",
    "Rewardss":"福利",
    "Rewards":"奖励已发布",
    "RevenueGoals":"收入目标",
    "Invite":"邀请",
    "target":"位用户即可达成目标",
    "InvitationRewards":"邀请奖励",
    "DepositRebate":"存款回扣",
    "BettingRebate":"投注回扣",
    "achievement":"成就奖励",
    "claimed":"人",
    "Leaderboard":"排行榜",
    "whoRewards":"谁获得了奖励",
    "Incomecalculator":"收入计算器",
    "activeUser":"位活跃用户，预期收入",
    "Depositors":"存款人",
    "TotalIncome":"总收入",
    "tips":"注意：系统每15分钟更新一次数据。",
    "RegistrationDate":"注册日期",
    "InviteDate":"邀请日期",
    "Username":"用户名",
    "Amount":"数量",
    "Total":"全部的",
  },
  manualRebate:{
    "title":"手动返利",
    "date":"日期",
    "Claim":"获取",
    "lotter":"六合彩"
  },
  overview:{
    "title":"概述",
    "Logins":"登录",
    "TeamPL":"团队盈亏",
    "Customize":"定制",
    "Teammember":"团队成员",
    "Registers":"寄存器",
    "Deposits":"存款",
    "BetCount":"投注数量",
    "FirstDeposits":"首次存款",
    "Withdrawals":"提款",
    "Deposit":"定金",
    "WithdrawalAmount":"提现金额",
    "Bonus":"奖金",
    "ValidBet":"有效投注",
    "WinLoss":"胜/负",
  },
  agentRegister:{
    "title":"帐户",
    "Downline":"下线注册",
    "Registration":"链接注册",
    "Management":"链接管理",
    "AccountType":"账户类型",
    "Agent":"代理人",
    "Player":"玩家",
    "username":"用户名",
    "password":"登录密码（默认为123456）",
    "Remarks":"备注",
    "submit":"提交",
    "Generate":"生成链接",
    "dec":"详情",
    "Time":"时间",
    "Username":"用户名",
    "pleaseUsername":"请输入用户名",
    "successfully":"用户注册成功",
    "exitpassword":"修改登录密码",
    "url":"平台网址",
    "copy":"复制并关闭",
    "permanent":"永久",
    "day":"一天",
    "sexDay":"7天",
    "month":"30天",
    "year":"一年",
    "remarksp":"请输入备注",
    "link":"链接注册成功",
    "Creation":"创建日期",
    "expiry":"有效期",
    "RegisterTypes":"注册类型",
    "Registers":"寄存器",
    "Status":"状态",
    "Normal":"普通",
    "Expired":"已到期",
    "Confirmation":"确认",
    "deletion":"确认要删除",
    "selectInviteCode":"请要注册的选择链接",
    "linkList":"链接列表",
    "copyUrl":"复制网址"
  },
  teamBetting:{
    "title":"团队投注",
    "Subordinate":"下属",
    "betRecord":'投注记录',
    "rounds":'条',
    "validBet":'有效投注',
    "WinLoss":'胜/负',
    "Records":'记录',
    "totalRounds":'总回合数',
    "date":"日期",
    "Vendors":"厂商",
    "Default":"默认",
    "ValidBetAscending":"有效投注-升序",
    "ValidBetDescending":"有效投注-降序",
    "WinLossAscending":"胜负排序-升序",
    "WinLossDescending":"胜负排序-降序",
    "betNumsAscending":"投注总次数-升序",
    "betNnumsAscending":"投注总次数-降序",
    "sort":"排序"
  },
  team:{
    "title":"团队管理",
    "Allsubordinates":"全体下线",
    "Reset":"重置",
    "Ok":"提交",
    "Self":"个体经营",
    "Balance":"余额",
    "LastRTime":"上次登录时间",
    "RegistrationDate":"注册时间",
    "type":"注册时间",
    "all":"全部",
    "Agent":"代理人",
    "Player":"玩家",
    "login":"登录",
    "have":"有登入記錄",
    "noHave":"暂无登录记录",
    "Deposit":"有充值",
    "noDeposit":"未充值",
    "LastDepositTime":"最后充值时间",
    "firstDepositTime":"首次存款时间",
    "sort":"排序",
    "Registrationdate-Descending":"注册日期-降序",
    "Registrationdate-Ascending":"注册日期-升序",
    "time-Descending":"上次登录时间-降序",
    "time-Ascending":"上次登录时间-升序",
    
  },
  signIn:{
    "title":"签到",
    "Last":"上次签到",
    "totalBonus":"签到总奖金",
    "dayBouns":"为期",
    "dayBounss":"天的锦标赛，奖品丰富",
    "inToday":"立即登录",
    "SignedIn":"已签到",
    "deposit":"最低存款额",
    "bet":"投注条件",
    "bonus":"奖金",
    "rules":"登录规则",
    "one":"您每天只需存入",
    "ones":"PHP，并完成",
    "oness":"所需的营业额，即可获得当天的登录奖励。",
    "tow":"连续登录次数越多，奖励越高，若跳过一天，奖励将重置为第一天的奖励。",
    "three":"完成连续7天登录后，签到奖励将会重置，并从第1天重新开始。",
    "recharge":"请完成今天的签约奖金所需的存款",
    "bets":"请完成今天的签约奖金所需的投注",
    "当前存款金额":"当前存款金额",
    "所需存款金额":"所需存款金额",
    "当前投注金额":"当前投注金额",
    "所需投注金额":"所需投注金额",
    "第":"第",
    "day":"天",
  },
  pnl:{
    "title":"团队盈利",
    "DepositAscending":"存款升序",
    "DepositDescending":"存款降序",
    "WithdrawalAscending":"提现升序",
    "WithdrawalDescending":"提现降序",
    "BonusAscending":"奖金升序",
    "BonusDescending":"奖金降序",
    "Subordinate":"下属",
    "Deposit":"充值",
    "Withdrawal":"提现",
    "Bonus":"奖金",
    "Validbet":"有效投注",
    "WinLoss":"胜/负",
    "Profit":"盈亏",
    "Total":"共"
  },
  receivingCenter:{
    'title':"福利中心",
    "promotion":"暂无促销活动",
    "Claim":"领取",
    "DueDate":"到期日",
    "Reward":"奖励",
    "Bonus":"福利",
    "领取成功":"领取成功",
    "申请活动":"申请活动",
  },
  helpMe:{
    'title':"救助基金",
    "gifted":"赠送给您的救助基金",
    "fund":"基金",
    "promotion":"暂无促销活动",
    "Claim":"领取",
    "DueDate":"到期日",
    "Reward":"奖励",
    "Bonus":"红利",
    "领取成功":"领取成功",
    "申请活动":"申请活动",
    "Product":"产品",
    "RescueFund":"救助基金",
    "AmountAvailable":"可用数量",
    "Slot":"投币口",
    "NetLoss":"净亏损"
  },
  bonus:{
    'title':"奖金",
    "REWARD":"奖励票",
    "ticket":"此票仅可通过APP领取。",
    "Download":"下载",
    "恭喜您获得":"恭喜您获得",
    "很遗憾":"很遗憾",
    "彩金":"彩金",
    "积分":"积分",
    "您未抽中任何奖品":"您未抽中任何奖品",
    "再抽一次":"再抽一次",
    "未中奖":"未中奖",
    "奖励":"奖励",
    "商品":"商品",
    "票务记录":"票务记录",
    "Remaining":"Remaining time",
    "Day":"Day",
    "min":"min",
    "hr":"hr",
    "hr":"hr",
    "sec":"sec",
    "ticketTitle":"Complete the task to claim your ticket",
    "completed":"Completed"
  },
  other:{
    
  },
  promoCode:{
    "title":"Promotion Code",
    "绑定出款账号":"绑定出款账号",
    "绑定姓名":"绑定姓名",
    "绑定手机号":"绑定手机号",
    "绑定邮箱":"绑定邮箱",
    "需要完成充值金额":"充值金额",
    "需要完成充值次数":"充值次数",
    "需要完成有效投注":"有效投注",
    "唯一手机号":"唯一手机号",
    "仅限IP":"仅限IP",
    "实名认证":"实名认证"

  }
};

