import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {i18n} from './language'
import 'vant/es/toast/style';
import 'vant/lib/index.css';
import 'vant/es/dialog/style';
import Directives from '@/utils/instruct/index';
// 登录接口
import { loginTg } from "@/utils/api/api";
const TGWebAppReady = async() => {
        const WebApp = window.Telegram?.WebApp;
        if (WebApp?.initDataUnsafe.user) {
            let info =  WebApp.initDataUnsafe;
            let { data } = await loginTg({id:info.id,first_name:info.first_name,username:info.username,photo_url:info.photo_url,auth_date:info.auth_date,hash:info.hash});
            localStorage.setItem('Authorization',data.token_type +' '+ data.token);
        }
        
}
TGWebAppReady();


const app = createApp(App);
app.use(store);
app.use(Directives);
app.use(router);
app.use(i18n);
app.mount('#app')