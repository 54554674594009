
import { authUidBind } from "@/utils/api/api";
class WebSocketClient {
    constructor() {
        this.url = baseConfigs.config_basic.chat_domain;
        this.ws = null;
        this.eventHandlers = {};
        this.heartbeatInterval = 40000; // 30秒心跳间隔
        this.heartbeatTimer = null;
        this.isClosed = false;
        this.connect();
    }
    connect() {
        this.ws = new WebSocket(this.url);

        this.ws.onopen = () => {
            console.log('WebSocket连接已建立');
            this.startHeartbeat(); // 开始心跳
        };

        this.ws.onmessage = (event) => {
            this.handleMessage(event.data);
        };

        this.ws.onclose = () => {
            console.log('WebSocket连接已关闭');
            this.stopHeartbeat(); // 停止心跳
            this.isClosed = true; // 更新状态为已关闭
            // 可根据需要添加重连逻辑
        };

        this.ws.onerror = (error) => {
            console.error('WebSocket出错', error);
        };
    }

    async handleMessage(message) {
        const data = JSON.parse(message);
        console.log('WebSocket收到消息', data);
        const eventType = data.type;
        await authUidBind({fd:data.client_id});
        if (this.eventHandlers[eventType]) {
            this.eventHandlers[eventType](data.payload);
        }
    }

    on(eventType, handler) {
        this.eventHandlers[eventType] = handler;
    }

    send(eventType, payload) {
        if (this.isClosed) {
            console.log('WebSocket连接已关闭，无法发送消息');
            return; // 如果连接已关闭，直接返回
        }
        const message = JSON.stringify({ type: eventType, payload });
        this.ws.send(message);
    }
    startHeartbeat() {
        this.heartbeatTimer = setInterval(() => {
            this.send("pong"); // 发送心跳消息
        }, this.heartbeatInterval);
    }

    stopHeartbeat() {
        if (this.heartbeatTimer) {
            clearInterval(this.heartbeatTimer);
            this.heartbeatTimer = null;
        }
    }

    close() {
        if (this.ws) {
            console.log('WebSocket连接正在关闭');
            this.stopHeartbeat(); // 关闭前停止心跳
            this.ws.close();
        }
    }
}

export default WebSocketClient;
