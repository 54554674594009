import request from "./server";
import { PORT1 } from "../config/servicePort";

// 登录接口
export const login = (data) => {
    return request.post(PORT1 + `/auth/login`, data, true);
};
// 图型验证码
export const captcha = (data) => {
    return request.get(PORT1 + `/auth/captcha`, data);
};
// 短信发送
export const sendSms = (data) => {
    return request.post(PORT1 + `/send/sms`, data, true);
};
// 注册接口
export const register = (data) => {
    return request.post(PORT1 + `/auth/register`, data, true);
};
// 用户信息接口
export const userInfo = (data) => {
    return request.get(PORT1 + `/user/detail`, data);
};
// 图片上传接口
export const uploadImage = (data) => {
    return request.post(PORT1 + `/upload/image`, data, true);
};
// 修改登录密码
export const normalRetry = (data) => {
    return request.post(PORT1 + `/user/normal_retry`, data, true);
};
// 修改基本信息
export const userinfoUpdata = (data) => {
    return request.put(PORT1 + `/user/userinfo`, data, true);
};
// 修改生日
export const userinfoUpdataBirthday = (data) => {
    return request.put(PORT1 + `/user/update_birthday`, data, true);
};

// 修改交易密码
export const fundsUpdata = (data) => {
    return request.put(PORT1 + `/user/update_funds_password`, data, true);
};
// 添加交易密码
export const fundsAdd = (data) => {
    return request.post(PORT1 + `/user/update_funds_password`, data, true);
};
// 忘记密码
export const reset_pwd = (data) => {
    return request.put(PORT1 + `/auth/reset_pwd`, data, true);
};
//  私信消息数量
export const privateNum = (data) => {
    return request.get(PORT1 + `/message/private_num`, data);
};
//tg 登录
export const loginTg = (data) => {
    return request.post(PORT1 + `/login/telegram/callback`, data);
};

// 金额获取
export const userMoney = (data) => {
    return request.get(PORT1 + `/user/money`, data);
};
// 绑定uid
export const authUidBind = (data) => {
    return request.post(PORT1 + `/auth/uidBind`, data);
};
// 获取 优惠券列表
export const couponList = (data) => {
    return request.get(PORT1 + `/coupon/list`, data);
};

// 领取优惠券
export const couponReceive = (data) => {
    return request.post(PORT1 + `/coupon/exchange`, data,true);
};

