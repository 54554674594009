export default {
    config: {
      "cancel": "kanselahin",
      "confirm": "confirm",
      "Today": "kumpirmahin",
      "Yesterday": "Kahapon",
      "sixday": "7-araw",
      "month":"30 araw",
      "SMS": "Pakilagay ang SMS verification code",
      "mobile":"Mangyaring ipasok ang numero ng mobile phone",
      "loading":"naglo-load",
      "gameerror":"Error sa laro",
      "取消收藏":"Kanselahin ang mga paborito",
      "收藏成功":"Matagumpay ang koleksyon",
      "uploadImages":"Mangyaring mag-upload ng mga larawan sa tamang format",
      "充值":"top up",
      "全屏":"buong screen",
      "success":'Tagumpay',
      "身份信息修改成功":"Matagumpay na nabago ang impormasyon ng pagkakakilanlan",
      "添加成功":"Matagumpay na naidagdag",
      "全部":"Lahat",
      "热门":"Mainit",
      "最近":"Kamakailan lang",
      "收藏":"Mangolekta",
      "请输入搜索关键词":"Mangyaring magpasok ng mga keyword sa paghahanap",
      "logout":"Sigurado ka bang gusto mong mag-logout?",
      "下属名称":"Subordinate na pangalan",
    },
    login: {
      "title": "Mag-login",
      "Remember": "Tandaan",
      "password": "Nakalimutan ang password",
      "Register": "Magrehistro",
      "account": "Walang account",
      "userPlaceholder": "Username",
      "passwordPlaceholder": "Password",
      "tips": "Mangyaring maglagay ng 6 - 13 alphanumeric na walang espesyal na karakter",
      "SMS": "Pakilagay ang SMS verification code",
      "mobile":"Mangyaring ipasok ang numero ng mobile phone",
      "code":"Pakilagay ang verification code",
      "Kind":"Mabait na tips",
      "first":"Pakikumpleto muna ang pag-verify",
      "graphic":"Pakilagay ang graphic verification code",
      "Send":"Magpadala ng code",
    },
    register: {
      "title": "Magrehistro",
      "Reset": "I-reset",
      "userPlaceholder": "Pakipasok ang Username",
      "passwordPlaceholder": "Mangyaring ipasok ang Password",
      "confirmPlaceholder": "Kumpirmahin ang password",
      "phonePlaceholder": "Numero ng mobile",
      "empty":"Hindi maaaring walang laman ang nilalaman",
      "tips": "Mangyaring maglagay ng 6 - 13 alphanumeric na walang espesyal na karakter",
      "tipsTow": "Mangyaring ipasok ang username at password",
      "tipsTows": "Hindi magkatugma ang dalawang password",
      "tipsThree": "Mangyaring magpasok ng 10 - 11 alphanumeric na walang espesyal na character",
      "invitation":"Mangyaring ipasok ang code ng imbitasyon",
    },
    forget:{
      "Forgot":"Nakalimutan ang password",
      "following":"Mangyaring kunin ang iyong password sa mga sumusunod na paraan.",
      "newpassword":"Mangyaring magpasok ng bagong password",
      "passwordagain":"Mangyaring magpasok muli ng bagong password",
      "wait":"Nire-reset ang password, mangyaring maghintay",
    },
    member: {
      "head": "Aking Account",
      "center": "Member Center",
      "Reward": "Sentro ng Gantimpala",
      "Betting": "Pagtaya Record",
      "Profit": "Kita At Pagkalugi",
      "Deposit": "Rekord ng Deposito",
      "Withdrawal": "Rekord ng Pag-withdraw",
      "Account": "Record ng Account",
      "MyAccount": "Aking Account",
      "Security": "Sentro ng Seguridad",
      "Invite": "Mag-imbita ng mga kaibigan",
      "Manual": "Manu-manong rebate",
      "Rebate": "Aking Rebate",
      "Internal": "Panloob na Mensahe",
      "Suggestion": "Mungkahi",
      "Service": "Serbisyo sa Customer",
      "Signin": "Mag-sign in",
      "Nickname": "palayaw",
      "DepositH": "Deposito",
      "Withdrawals": "Pag-withdraw",
      "Cards": "Aking Mga Kard",
      "Benefits": "Mga Benepisyo",
      "Overview":"Pangkalahatang-ideya",
      "Registration":"Pagpaparehistro",
      "TeamManagement":"Pamamahala ng Koponan",
      "TeamBetting":"Pagtaya sa Koponan",
      "TeamPL":"Team PL",
      "Announcement":"Anunsyo",
      "anage":"Sentro ng ahente"
    },
    feedback: {
      "title": "Reklamo / mungkahi",
      "type": "Mangyaring piliin ang uri ng tanong",
      "contentTite": "Mayroon bang anumang bagay na maaaring mapabuti?",
      "content": "Mangyaring magpasok ng nilalaman",
      "upload": "Mag-upload",
      "code": "* Verification Code",
      "submit": "Isumite",
      "select": "Mangyaring piliin ang uri ng isyu"
    },
    addBankCard: {
      "title": "Numero ng Bank Account",
      "card": "Mangyaring punan ang bank account number",
      "name": "Mangyaring punan ang pangalan ng nagbabayad",
      "phone":"Mangyaring punan ang iyong numero ng mobile phone",
      "code":"Mangyaring punan ang verification code",
      "tips": "Pakitiyak na ang pangalan ay tumutugma sa bank card upang maiwasan ang pagkabigo sa pag-withdraw.",
      "submit": "Isumite",
      "select": "Pumili ng bangko",
      "address":"Mangyaring punan ang impormasyon ng sangay",
      "password": "Mangyaring punan ang password ng pondo",
    },
    addBankCardPix:{
      "title":"Magbigkis ng E-wallet",
      "group":"Piliin ang pangkat ng E-wallet",
      "type":"Uri ng e-wallet",
      "agreement":"protocol",
      "submit":"Isumite",
      "account":"Mangyaring punan ang account number",
      "address":"Mangyaring punan ang impormasyon ng sangay",
      "virtual":"virtual na pera",
      "E-wallet":"E-wallet",
    },
    webEmail: {
      "title": "Panloob na Mensahe",
      "Inbox": "Inbox",
      "selectAll": "Piliin ang Lahat",
      "Sender": "Nagpadala",
      "tit": "pamagat",
      "Confirmation": "Kumpirmasyon",
      "delDes": "Sigurado ka bang gusto mong tanggalin ito?",
      "message":"Walang mensahe",
      "Sender":"Nagpadala",
      "titls":"Pamagat",
      "Time":"Oras",
    },
    notice: {
      "title": "Anunsyo",
    },
    voucherCenter: {
      "title": "Deposito",
      "deposit": "Paraan ng deposito",
      "channels": "Mga channel ng pagbabayad",
      "amount": "Halaga ng Deposito",
      "virtual": "Virtual currency protocol",
      "Next": "Susunod",
      "Minimum": "Minimum na halaga",
      "Maximum": "maximum na halaga",
      "DepositInfo":"Impormasyon sa deposito",
      "order":"Inilagay ang order",
      "account":"deposit account",
      "money":"Halaga ng deposito",
      "copy":"Kopyahin",
      "tips":"Mga tip",
      "connection":"Kung mayroon kang anumang mga katanungan, mangyaring huwag mag-atubiling makipag-ugnay sa amin.",
      "TransactionRecord":"Record ng Transaksyon",
      "CompleteDeposit":"Kumpletong Deposito",
      "Another":"Isa pa",
      "service":"serbisyo",
      "noActivity":"Hindi kasali sa event",
      "Activity":"Aktibidad",
      "depositorname":"Pakilagay ang pangalan ng depositor",
      "successPay":"tagumpay! Mangyaring pumunta sa pahina ng deposito upang magbayad",
      "protocol":"protocol ng pitaka",
      "cardName":"Pangalan ng bank card",
      "address":"address ng pitaka",
      "cardAddress":"Bank card account number",
      "virtual":"Dami ng mga virtual na barya",
      "Ordernumber":"Numero ng order",
      "rate":"halaga ng palitan",
      "period":"Panahon ng bisa",
      "tips1":"1. Mangyaring kumpirmahin ang mga singil bago magbayad, ang halaga ng deposito ay ibabatay sa halagang natanggap.",
      "tips2":"2. Mangyaring kumpletuhin ang pagbabayad sa loob ng 30min, tantiyahin ang oras ng pagdedeposito ay mga 3min - 20min. Kung hindi mo ito natanggap sa loob ng tagal, mangyaring ibigay ang iyong transaction ID, wallet address at transaction hash para sa mas mabilis na pagproseso, salamat.",
    },
    activity: {
      "title": "Promosyon",
    },
    myWithdrawCards: {
      "title": "Aking Mga Kard",
      "wallet": "Idinagdag ang e-wallet",
      "emptyWallet": "walang laman na wallet",
      "bank": "Idinagdag ang bank account",
      "emptyBank": "walang laman na bangko",
      "addnew": "idinagdag",
      "walletName": "E-wallet",
    },
    benefits: {
      "title": "Mga Benepisyo sa Ranggo",
      "Rules": "Mga tuntunin",
      "Next": "Susunod na Ranggo",
      "up":"Nakaraang antas",
      "Levels": "Mga Kinakailangan sa Level Up",
      "Upgrade": "Mag-upgrade",
      "Fulfil": "Tuparin ang lahat para mag-level up",
      "TotalBet": "Kabuuang Taya",
      "TotalDeposit":"Kabuuang Deposito",
      "go": "GO",
      "withdrawals": "mga withdrawal bawat araw",
      "quota": "Pang-araw-araw na limitasyon sa pag-withdraw",
      "UpgradesQuota": "I-upgrade ang regalo",
      "birthday": "Regalo sa kaarawan",
      "week": "Lingguhang regalo",
      "Completed":"Nakumpleto"
    },
    nickName: {
      "title": "palayaw",
      "Submit": "Isumite",
      "Please": "Pakilagay ang iyong palayaw",
      "complete": "Nagtagumpay ang pagbabago ng palayaw"
    },
    avatar: {
      "uploadImage": "I-click ang larawan upang i-upload ang iyong sariling avatar",
      "Classic": "Klasikong avatar",
      "ok": "Ok",
    },
    withdraw: {
      "title": "Pag-withdraw",
      "mybank": "Aking mga bank account",
      "bank": "Mga Account sa bangko",
      "banks": "Walang laman na Bangko",
      "walletsss": "Walang laman ang mga E-wallet",
      "walletss": "E-wallet",
      "wallets": "Bound E-wallet",
      "Account": "Account number",
      "BindTiem": "Petsa ng pagbubuklod",
      "withdrawTiem": "Oras ng pag-withdraw",
      "Tips": "Mga tip",
      "other": "Tandaan: Pakitiyak na ang iyong GCASH ay na-verify para sa withdrawal application. Kung hindi nakumpleto ang pag-verify, hindi ito mai-kredito sa account",
      "Daily": "Araw-araw na withdrawal",
      "Remaining": "Natitirang withdrawal",
      "Times": "Mga oras",
      "MainWallet": "Pangunahing wallet",
      "type": "Uri ng pag-withdraw",
      "AvailableAmount": "Magagamit na halaga",
      "balance": "Alalahanin ang balanse",
      "Ok":"Ok",
      "turnover":"Mga kinakailangan sa paglilipat ng withdrawal",
      "completeTurnover":"Mangyaring kumpletuhin ang kinakailangang turnover para sa withdrawal.",
      "GameType":"Uri ng laro",
      "RemainingRurnover":"Natitirang runover",
      "General":"Heneral",
      "amount":"halaga",
      "mobile":"mobile",
      "verify_code":"Verification code",
      "trade_password":"Password ng transaksyon",
      "trade_password_p":"Pakipasok ang password ng transaksyon",
      "submit":"Isumite",
      "BalanceSuccessful":"Matagumpay ang pagbabalik ng balanse",
      "Theinputamountcannotbelessthan":"Ang halaga ng input ay hindi maaaring mas mababa sa",
      "greater":"mas malaki kaysa sa",
      "mobiles":"Mangyaring magpasok ng 10 hanggang 11 digit na numero ng mobile phone",
      "card":"Mangyaring magdagdag ng bank card",
      "money":"Mangyaring ipasok ang halaga ng withdrawal",
      "mobileE":"Hindi maaaring walang laman ang numero ng mobile phone",
      "verify_codeE":"Hindi maaaring walang laman ang verification code",
      "order":"Naisumite na ang withdrawal order",
    },
    myAccount: {
      "title": "Aking Account",
      "username": "Username",
      "privacy": "Pinapahalagahan namin ang iyong privacy",
      "protected": "Ang lahat ng data ng user ay naka-encrypt upang matiyak na protektado ang iyong personal na privacy.",
      "nickname": "Pakilagay ang iyong palayaw",
      "email": "Pakilagay ang iyong email",
      "real_name": "Pakilagay ang iyong pangalan",
      "mobile": "Mangyaring ipasok ang iyong mobile",
      "real_name_tips": "Pakitiyak na ang iyong pangalan ay tumutugma sa iyong impormasyon sa pag-alis",
      "email_tips": "Mangyaring magpasok ng 1 - 255 character sa format ng mailbox",
      "mobile_tips": "Mangyaring magpasok ng 10 - 11 character",
      "Submit": "Isumite",
    },
    vouReport: {
      "title": "Rekord ng Deposito",
      "Deposit": "Deposit ref",
      "Postscript": "Postscript",
      "ReceivedTime": "Natanggap na oras",
      "fee": "Bayad sa paghawak",
      "Activity": "Aktibidad",
      "Request": "Kahilingan",
      "Received": "Natanggap na halaga",
      "Status": "Katayuan",
      "type": "Mga uri",
      "discount":"Halaga ng diskwento",
      "待付款":"Nakabinbing bayad",
      "付款待审核":"Nakabinbing pagsusuri",
      "已完成":"Nakumpleto",
      "已取消":"Kinansela",
      "挂单":"nakabinbing order",
      "拒绝":"tanggihan",
      "待出款":"Hindi binayaran",
    },
    withdrawReport: {
      "title": "Rekord ng Pag-withdraw",
      "Request": "halaga ng withdrawal",
    },
    gameRecord: {
      "title": "Pagtaya Record",
      "all": "Lahat",
      "order": "Order no.",
      "BetAmount": "Ang halaga ng taya",
      "ValidBet": "Wastong taya",
      "ProfitAndLoss": "Kita at pagkalugi",
      "Winnings": "Mga panalo",
    },
    loginChange: {
      "title": "Baguhin ang password sa pag-login",
      "tipContent": "Mangyaring magpasok ng 6 - 12 alphanumeric na walang espesyal na character",
      "Submit": "Isumite",
      "oldNewPassowrd": "Ang dalawang password ay hindi pare-pareho",
      "kong": "Mangyaring ipasok ang nilalaman",
      "oldPassword": "Ilagay ang iyong kasalukuyang password.",
      "newPassword": "Mangyaring magpasok ng bagong password",
      "ConfirmPassword": "Kumpirmahin ang bagong password",
      "Confirm":"Matagumpay na nabago ang password sa pag-login",
    },
    FundsPasswordBox: {
      "title": "Password ng Transaksyon",
      "exit":"Matagumpay na nabago ang password ng transaksyon",
      "add":"Matagumpay na magdagdag ng password sa transaksyon",
    },
    securityCenter: {
      "title": "Sentro ng Seguridad",
      "tips-up": "Ang antas ng seguridad ng iyong account ay",
      "tips-nx": "Mangyaring pagbutihin ang iyong impormasyon sa kaligtasan",
      "information": "Personal na impormasyon",
      "informationTips": "Kumpletuhin ang personal na impormasyon.",
      "loginPassword": "Baguhin ang password sa pag-login",
      "loginPasswordTips": "Inirerekomenda na kumbinasyon ng titik at numero",
      "password": "Password ng Transaksyon",
      "passwordTips": "Magtakda ng password ng pondo upang mapabuti ang seguridad ng mga pagpapatakbo ng pondo",
      "card": "Bank Account Number",
      "cardTips": "Mangyaring gumamit ng totoong bank account number",
      "wallet": "Magbigkis ng E-wallet",
      "walletTips": "Bind E-wallet para sa withdrawal.",
      "login": "Mag-logout",
      "loginTips": "Ligtas na mag-logout",
      "Safety": "Porsyento ng kaligtasan",
      "LastIp": "Huling login ip",
      "lastTiem": "Huling oras ng pag-log in",
      "high": "Mataas",
      "In": "Sa",
      "low": "Mababa",
      "birthday":"Bind birthday",
      "birthdayTops":"Itali ang petsa ng iyong kaarawan",
      "birthdayTitle":"kaarawan"
    },
    myBankCards: {
      "title": "Bank Account Number",
      "addBank": "Idinagdag ang bank account",
      "Maximum": "Pinakamataas",
      "allowed": "pinapayagan",
      "Empty": "Walang laman na bangko"
    },
    myEWallet: {
      "title": "Magbigkis ng E-wallet",
      "addBank": "Idinagdag ang e-wallet",
      "Empty": "Walang laman ang E-wallet",
    },
    profitandloss: {
      "title": "Personal na ulat",
      "Total": "Kabuuang P&L",
      "Deposit": "Deposito",
      "Activity": "Aktibidad",
      "Income": "Kita",
      "Withdrawal": "Pag-withdraw",
      "Expenses": "Mga gastos",
      "Betting": "pagtaya",
      "ValidBet": "Wastong taya",
      "winAmount": "Ang halaga ng panalo",
      "Rebate": "Rebate",
      "Bonus": "Bonus",
    },
    inviteFriends:{
      "title":"Mag-imbita ng mga kaibigan",
      "Overview":"Pangkalahatang-ideya",
      "Incomes":"Mga kita",
      "Records":"Mga rekord",
      "Invitee":"Inaanyayahan",
      "TodayIncome":"Ang Kita Ngayon",
      "YesterdayIncome":"Kita kahapon",
      "Registers":"Nagrerehistro",
      "ValidInvitees":"Mga Wastong Inimbitahan",
      "URL":"URL ng imbitasyon",
      "share":"ibahagi",
      "copy":"Kopyahin link",
      "Rewardss":"Mga gantimpala",
      "Rewards":"Mga reward na inilabas sa ngayon",
      "RevenueGoals":"Mga layunin sa kita",
      "Invite":"Mag-imbita",
      "target":" user upang matugunan ang target",
      "InvitationRewards":"Mga Gantimpala sa Imbitasyon",
      "DepositRebate":"Rebate ng Deposito",
      "BettingRebate":"Rebate sa pagtaya",
      "achievement":"Mga gantimpala sa tagumpay",
      "claimed":"inaangkin",
      "Leaderboard":"Leaderboard",
      "whoRewards":"Sino ang nakatanggap ng mga gantimpala",
      "Incomecalculator":"Calculator ng kita",
      "activeUser":" mga aktibong user, inaasahang kita",
      "Depositors":"Mga depositor",
      "TotalIncome":"Kabuuang Kita",
      "tips":"Tandaan: Ina-update ng system ang data tuwing 15 minuto.",
      "RegistrationDate":"Petsa ng pagpaparehistro",
      "InviteDate":"Petsa ng Pag-imbita",
      "Username":"Username",
      "Amount":"Halaga",
      "Total":"Kabuuan",
    },
    manualRebate:{
      "title":"Manu-manong rebate",
      "date":"Petsa",
      "Claim":"Claim",
      "lotter":"Lotter"
    },
    overview:{
      "title":"Pangkalahatang-ideya",
      "Logins":"Mga pag-login",
      "TeamPL":"Team P&L",
      "Customize":"I-customize",
      "Teammember":"Miyembro ng pangkat",
      "Registers":"Nagrerehistro",
      "Deposits":"Mga deposito",
      "BetCount":"Bilang ng taya",
      "FirstDeposits":"Mga unang deposito",
      "Withdrawals":"Mga withdrawal",
      "Deposit":"Deposito",
      "WithdrawalAmount":"Halaga ng Pag-withdraw",
      "Bonus":"Bonus",
      "ValidBet":"Wastong taya",
      "WinLoss":"Panalo/Talo",
    },
    agentRegister:{
      "title":"Account",
      "Downline":"Pagpaparehistro sa Downline",
      "Registration":"Pagpaparehistro ng Link",
      "Management":"Pamamahala ng Link",
      "AccountType":"Uri ng account",
      "Agent":"Ahente",
      "Player":"Manlalaro",
      "username":"Username",
      "password":"Password sa pag-login (ang default ay 123456)",
      "Remarks":"Remarks",
      "submit":"Isumite",
      "Generate":"Bumuo ng link",
      "dec":"Mga Detalye",
      "Time":"Oras",
      "Username":"Username",
      "pleaseUsername":"Mangyaring ipasok ang username",
      "successfully":"Matagumpay ang pagpaparehistro ng user",
      "exitpassword":"Baguhin ang password sa pag-login",
      "url":"URL ng platform",
      "copy":"Kopyahin at isara",
      "permanent":"permanente",
      "day":"isang araw",
      "sexDay":"7 araw",
      "month":"30 araw",
      "year":"isang taon",
      "remarksp":"Mangyaring maglagay ng mga puna",
      "link":"Matagumpay ang pagpaparehistro ng link",
      "Creation":"Petsa ng paglikha",
      "expiry":"Petsa ng pag-expire",
      "RegisterTypes":"Mga Uri ng Register",
      "Registers":"Nagrerehistro",
      "Status":"Katayuan",
      "Normal":"Normal",
      "Expired":"Nag-expire na",
      "Confirmation":"Kumpirmasyon",
      "deletion":"Kumpirmahin ang pagtanggal:",
      "selectInviteCode":"Mangyaring pumili ng link upang magparehistro",
      "linkList":"Naka-link na listahan",
      "copyUrl":"Kopyahin ang URL"
    },
    teamBetting:{
      "title":"Pagtaya sa Koponan",
      "Subordinate":"nasasakupan",
      "betRecord":'Talaan ng taya',
      "rounds":'mga round',
      "validBet":'Wastong taya',
      "WinLoss":'Panalo/Talo',
      "Records":'Mga rekord',
      "totalRounds":'Kabuuang mga round',
      "date":"Petsa",
      "Vendors":"Mga nagtitinda",
      "Default":"Default",
      "ValidBetAscending":"Wastong taya-Pataas",
      "ValidBetDescending":"Wastong taya-Pababa",
      "WinLossAscending":"Panalo/Talo-Pataas",
      "WinLossDescending":"Panalo/Talo-Pababa",
      "betNumsAscending":"Kabuuang bilang ng mga taya-Pataas",
      "betNnumsAscending":"Kabuuang bilang ng mga taya-Pababa",
      "sort":"Sorting"
    },
    team:{
      "title":"Pamamahala ng Koponan",
      "Allsubordinates":"Lahat ng subordinates",
      "Reset":"I-reset",
      "Ok":"Ok",
      "Self":"sarili",
      "Balance":"Balanse",
      "LastRTime":"Huling oras ng pag-log in",
      "RegistrationDate":"Petsa ng pagpaparehistro",
      "type":"Petsa ng pagpaparehistro",
      "all":"Lahat",
      "Agent":"Ahente",
      "Player":"Manlalaro",
      "login":"Mag-login",
      "have":"Magkaroon ng rekord sa pag-login",
      "noHave":"Walang record sa pag-log in",
      "Deposit":"Nakadeposito",
      "noDeposit":"Walang deposito",
      "LastDepositTime":"Huling oras ng deposito",
      "firstDepositTime":"Unang oras ng deposito",
      "sort":"Pag-uuri",
      "Registrationdate-Descending":"Petsa ng pagpaparehistro-Pababa",
      "Registrationdate-Ascending":"Petsa ng pagpaparehistro-Pataas",
      "time-Descending":"Huling oras ng pag-log in-Pababa",
      "time-Ascending":"Huling oras ng pag-log in-Pataas",
      
    },
    home: {
      "hotGame": "Mainit na Laro",
      "service": "Serbisyo",
      "cashBack": "Cash Back",
      "Withdraw": "Mag-withdraw",
      "Bonus": "Bonus",
      "leaderBoard":"leaderBoard",
      "home": "Bahay",
      "agent": "Ahente",
      "activity": "Promosyon",
      "member": "Miyembro",
      "Confirmation":"Kumpirmasyon",
      "logout":"Sigurado ka bang gusto mong mag-logout?",
      "updates":"Nais naming idagdag ito sa iyong home screen para sa pinakabagong libreng taya at mga update sa bonus.",
      "Continue":"Magpatuloy",
      "benefits":"Tangkilikin ang higit pang mga benepisyo!",
      "Category":"Kategorya",
      "Providers":"Mga tagapagbigay",
      "OtherGames":"Iba pang Laro"
    },
    record: {
      "title": "Record ng Account",
      "order": "Order no.",
      "Balance": "Balanse",
      "Transaction": "Halaga ng transaksyon",
    },
    pnl:{
      "title":"Team P&L",
      "DepositAscending":"Deposit-Aascending",
      "DepositDescending":"Deposit-Pababa",
      "WithdrawalAscending":"Withdrawal-Aascending",
      "WithdrawalDescending":"Withdrawal-Pababa",
      "BonusAscending":"Bonus-Pataas",
      "BonusDescending":"Bonus-Pababa",
      "Subordinate":"nasasakupan",
      "Deposit":"Deposito",
      "Withdrawal":"Pag-withdraw",
      "Bonus":"Bonus",
      "Validbet":"Wastong taya",
      "WinLoss":"Panalo/Talo",
      "Profit":"Kita at pagkalugi",
      "Total":"Kabuuan"
    },
    signIn:{
      "title":"Mag-sign in",
      "Last":"Huling pag-sign in",
      "totalBonus":"Mag-sign in ng kabuuang bonuss",
      "dayBouns":"",
      "dayBounss":"-DAY TOURNAMENT MAY MGA PREMYO",
      "inToday":"Mag-sign in ngayon",
      "SignedIn":"Naka-sign in",
      "deposit":"Minimum na halaga ng deposito",
      "bet":"Mga kondisyon sa pagtaya",
      "bonus":"bonus",
      "rules":"Mga panuntunan sa pag-sign in",
      "one":"您每天只需存入",
      "ones":"PHP，并完成",
      "oness":"所需的营业额，即可获得当天的登录奖励。",
      "tow":"连续登录次数越多，奖励越高，若跳过一天，奖励将重置为第一天的奖励。",
      "three":"完成连续7天登录后，签到奖励将会重置，并从第1天重新开始。",
      "recharge":"Mangyaring kumpletuhin ang kinakailangang deposito para sa iyong sign-up bonus ngayon",
      "bets":"Mangyaring kumpletuhin ang pagtaya na kinakailangan para sa iyong sign-up bonus ngayon",
      "当前存款金额":"Kasalukuyang halaga ng deposito",
      "所需存款金额":"Kinakailangang halaga ng deposito",
      "当前投注金额":"Kasalukuyang halaga ng pagtaya",
      "所需投注金额":"Kinakailangang halaga ng pagtaya",
      "第":"",
      "day":"Araw",
    },
    receivingCenter:{
      'title':"Sentro ng Kapakanan",
      "promotion":"Wala pang available na promosyon",
      "Claim":"Claim",
      "DueDate":"Takdang Petsa",
      "Reward":"Gantimpala",
      "Bonus":"kapakanan",
      "领取成功":"Matagumpay na natanggap",
      "申请活动":"Mag-apply para sa isang kaganapan",
    },
    helpMe:{
      'title':"Rescue fund",
      "gifted":"Relief fund na regalo sa iyo",
      "fund":"Pondo",
      "promotion":"Wala pang promo",
      "Claim":"Claim",
      "DueDate":"Takdang Petsa",
      "Reward":"Gantimpala",
      "Bonus":"Bonus",
      "领取成功":"Matagumpay na natanggap",
      "申请活动":"Mag-apply para sa isang kaganapan",
      "Product":"produkto",
      "RescueFund":"Rescue fund",
      "AmountAvailable":"Halagang magagamit",
      "Slot":"Puwang",
      "NetLoss":"Net loss"
    },
    bonus:{
      'title':"bonus",
      "REWARD":"REWARD TICKET",
      "ticket":"Ang ticket na ito ay maaari lamang i-claim gamit ang APP.",
      "Download":"I-download",
      "恭喜您获得":"Congratulations sa pagkuha",
      "很遗憾":"It's a pity",
      "彩金":"bonus",
      "积分":"integral",
      "您未抽中任何奖品":"Hindi ka nanalo ng anumang mga premyo",
      "再抽一次":"Usok ulit",
      "未中奖":"walang panalo",
      "奖励":"parangal",
      "商品":"kalakal",
      "票务记录":"Mga talaan ng tiket",
      "Remaining":"Natitirang oras",
      "Day":"Araw",
      "min":"min",
      "hr":"hr",
      "sec":"sec",
      "ticketTitle":"Complete the task to claim your ticket",
      "Completed":"Completed"
    },
    promoCode:{
      "title":"Promotion Code",
      "绑定出款账号":"Bind your withdrawal account",
      "绑定姓名":"Bind your name",
      "绑定手机号":"Bind your mobile number",
      "绑定邮箱":"Bind your email",
      "需要完成充值金额":"Deposit amount",
      "需要完成充值次数":"Deposit Number",
      "需要完成有效投注":"Valid bet"
    }
  };
  