import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

import { ref } from 'vue';
const _withScopeId = n => (_pushScopeId("data-v-32c51f08"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "loading"
};
const _hoisted_2 = ["src"];
export default {
  __name: 'oneLoad',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const bg = ref(baseConfigs.config_basic.sys_h5_img);
    const https = ref(baseConfigs.config_basic.image_cloud_url);
    return (_ctx, _cache) => {
      return __props.show ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
        src: _unref(https) + _unref(bg),
        alt: ""
      }, null, 8, _hoisted_2)])) : _createCommentVNode("", true);
    };
  }
};