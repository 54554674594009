import { createStore } from 'vuex'
// 请求银行卡接口
import { myBankList, withdrawList } from '@/utils/api/member';
import { welfareNum } from "@/utils/api/home";
import { userInfo, userMoney } from "@/utils/api/api";
import  WebSocketClient  from "@/utils/api/socket"
export default createStore({
  state: {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    //银行卡列表
    bankList: localStorage.getItem('bank') ? JSON.parse(localStorage.getItem('bank')) : [],
    //虚拟卡列表
    WalletList: localStorage.getItem('wallet') ? JSON.parse(localStorage.getItem('wallet')) : [],
    //提款列表
    withdrawLists:localStorage.getItem('withdrawLists') ? JSON.parse(localStorage.getItem('withdrawLists')) : [],
    webSocketClient: null,
    isLoggedIn: false,
    nums:{welfareNum:0,rescueNum:0,ticketName:0}
  },
  getters: {
  },
  mutations: {
    setWebSocketClient(state, client) {
      state.webSocketClient = client;
    },
    setLoggedIn(state, status) {
      state.isLoggedIn = status;
    },
    upLotteryCont(state, num) {
      state.user = num;
    },
    handleAddUserInfo(state, data) {
      state.user = data;
    },
    // 银行卡接口
    async handleBank(state, num) {
      let { data } = await myBankList({ type: 0 });

      let bank =[];
      let wallet = [];
      data.forEach(element => {
        console.log(element);
        if(element?.withdraw_config?.is_wallet == 0) {
          bank.push(element);
        } else {
          wallet.push(element);
        }
      });
      state.bankList = bank;
      state.WalletList = wallet;
      localStorage.setItem("bank", JSON.stringify(bank));
      localStorage.setItem("wallet", JSON.stringify(wallet));
    },
    // 提款列表接口
    async handleWithdraw(state, num) {
      let { data } = await withdrawList();
      state.withdrawLists = data;
      localStorage.setItem("withdrawLists", JSON.stringify(data));
    },
    // 用户信息接口
    async handleUserInfo (state){
      let { data } = await userInfo();
      localStorage.setItem("user", JSON.stringify(data));
      state.user = data;
    },
    //获取金额
    async handleUserMoney (state){
      let { data } = await userMoney();
      state.user.balance = data.money;
    },
    async handleBounsNum(state){
      try {
        let { data } = await welfareNum();
        state.nums = data.nums;
      } catch (error) {
        state.nums = null;
          // 处理异常的代码
      } 
 

    },
    //修改用户信息
    handleExitUserInfo(state,data){
      state.user = data;
    },

  },
  actions: {
    login({ commit }) {
      const client = new WebSocketClient();
      commit('setWebSocketClient', client);
      commit('setLoggedIn', true);
    },
    logout({ commit, state }) {
      if (state.webSocketClient) {
        state.webSocketClient.close();
        commit('setWebSocketClient', null);
      }
      commit('setLoggedIn', false);
    },
    getDeviceBank({ commit }) {
      commit('handleBank')
    },
    getDeviceUser({ commit }) {
      commit('handleUserInfo')
    },
    getDeviceWithdraw({ commit }) {
      commit('handleWithdraw')
    },
    getDeviceMoney({ commit }) {
      commit('handleUserMoney')
    },
    getDeviceBounsNum({ commit }) {
      commit('handleBounsNum')
    },
  },
  modules: {
  }
})
