import request from "./server";
import { PORT1 } from "../config/servicePort";

// 轮播图接口
export const carousels = (data) => {
    return request.get(PORT1 + `/carousels/list`, data);
};
// 首页通知接口
export const notice = (data) => {
    return request.get(PORT1 + `/notice/index`, data);
};
//游戏标签
export const getTag = (data) => {
    return request.post(PORT1 + `/otherGame/getTag`, data);
};
//游戏列表
export const getGame = (data) => {
    return request.post(PORT1 + `/otherGame/getGame`, data);
};
//游戏列表
export const getGameTwo = (data) => {
    return request.post(PORT1 + `/otherGame/getGameTwo`, data);
};
// 游戏厂商
export const getCompany = (data) => {
    return request.post(PORT1 + `/otherGame/getCompany`, data);
};
//获取游戏
export const getGameInfo = (data) => {
    return request.post(PORT1 + `/otherGame/getGameInfo`, data,true);
};
// 热门游戏
export const otherGameGetHots = (data) => {
    return request.get(PORT1 + `/otherGame/getHots`, data);
};
// 收藏游戏列表
export const otherGameGetCollects = (data) => {
    return request.get(PORT1 + `/otherGame/getCollects`, data);
};
// 收藏游戏
export const getGameCollect = (data) => {
    return request.post(PORT1 + `/otherGame/collect`, data);
};
// 投注记录
export const getGameBetRecord = (data) => {
    return request.post(PORT1 + `/otherGame/getBetRecord`, data);
};
// 个人报表
export const personalReport = (data) => {
    return request.post(PORT1 + `/otherGame/personalReport`, data,true);
};
// 游戏标签包含厂商
export const getGameTagTwo = (data) => {
    return request.post(PORT1 + `/otherGame/getTagTwo`, data);
};
// 福利数量
export const welfareNum = (data) => {
    return request.get(PORT1 + `/welfare/nums`, data);
};
// 签到信息
export const signinGet = (data) => {
    return request.get(PORT1 + `/signin/get`, data);
};
// 签到
export const signinIndex = (data) => {
    return request.post(PORT1 + `/signin/index`, data,true);
};
// 签到转盘抽奖
export const signinDraw = (data) => {
    return request.post(PORT1 + `/signin/draw`, data,true);
};
// 签到转盘抽奖详情
export const signinTransitDetails = (data) => {
    return request.post(PORT1 + `/signin/transitDetails`, data,true);
};
// 中奖列表
export const signinDrawList = (data) => {
    return request.get(PORT1 + `/signin/drawList`, data);
};
// 第二种签到
export const activitySigninGet = (data) => {
    return request.get(PORT1 + `/activitySignin/get`, data);
};
// 第二种领取
export const signinTransitIndex = (data) => {
    return request.post(PORT1 + `/activitySignin/index`, data,true);
};
// 票券详情
export const ticketingInfo = (data) => {
    return request.get(PORT1 + `/ticketing/info`, data);
};
// 领取票券
export const ticketingReceive = (data) => {
    return request.post(PORT1 + `/ticketing/receive`, data,true);
};
// 票券中奖记录
export const ticketingWinningRecord = (data) => {
    return request.get(PORT1 + `/ticketing/winningRecord`, data,true);
};

