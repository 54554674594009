import router from '@/router/index';
import { showToast } from 'vant';
// 页面跳转
export const handlenavGetTo = (name,data="") => {
    router.push({
        name,
        query:data
    })
}

// 页面跳转
export const copyContent = async(url) => {
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.setAttribute('value', url);
    input.setAttribute('readonly', 'readonly');
    input.select();
    input.setSelectionRange(0, 999);
    if (document.execCommand('copy')) {
        showToast("Copied successfully");
    }
    document.body.removeChild(input);
}
// 日期前后
export const DatePrevNext=(d,type='',n=1)=>{
    let  date=new Date();//每次调用重新实例化Date
    if(type=='next'){
        date.setTime(new Date(d).getTime()+(1000*60*60*24*n))//获取时间戳到当前时间的毫秒数减去一天时间的毫秒数
    }else{
        date.setTime(new Date(d).getTime()-(1000*60*60*24*n))//获取时间戳到当前时间的毫秒数减去一天时间的毫秒数
    };
    return formatDate(date);
}
const formatTen=(num)=>{
    return num > 9 ? (num + "") : ("0" + num);
}
//日期格式化
export const formatDate=(date)=>{
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return year +'-'+formatTen(month) +'-'+formatTen(day);
}
//获取当前日期日期格式化
export const dayMonth=()=>{
    let  date=new Date();//每次调用重新实例化Date
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return formatTen(month) +'/'+formatTen(day);
}
//获取当前日期日期格式化
export const dayYear=()=>{
    let  date=new Date();//每次调用重新实例化Date
    let year = date.getFullYear();
    return year;
}
export const dateStart = (tiems) => {
    let date=new Date(tiems);
    return formatDate(date)
}
//

