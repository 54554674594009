

import { debounce } from 'lodash'; 
export default {
  inserted(el, binding) {
    let timer = null;
    const debouncedHandler = debounce(() => {
      // 执行原本的点击处理函数
      binding.value();
    }, binding.arg ||1000,{
      'leading': true, //在延迟开始前立即调用事件
      'trailing': false, //在延时结束后不调用,保证事件只被触发一次
    }); // 默认防抖时间为1000ms，可通过v-debounce="[handler, time]"来自定义
 
    el.addEventListener('click', () => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        debouncedHandler();
      }, 0);
    });
  },
};