import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1aa0cb7e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "footer-home"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "icon-wrap"
};
const _hoisted_4 = {
  class: "middle"
};
import { useI18n } from 'vue-i18n'; // 多语言
import { ref } from "vue";
import { useStore } from "vuex"; // 引入useStore 方法
import { handlenavGetTo } from "@/utils/config/index";

import { computed } from 'vue';
export default {
  __name: 'TabBar',
  setup(__props) {
    // 引入vuex
    let {
      t
    } = useI18n();
    const store = useStore(); // 该方法用于返回store 实例
    const footerList = ref([{
      name: t('home.home'),
      bgImage: require("../assets/images/footer-home.9cc8a90f.png"),
      path: "home"
    }, {
      name: t('home.inviteFriends'),
      bgImage: require("../assets/images/agent.8a6d289b.png"),
      path: "inviteFriends"
    }, {
      name: "Login",
      bgImage: require("../assets/images/agent.8a6d289b.png"),
      path: "login"
    }, {
      name: t('home.activity'),
      bgImage: require("../assets/images/footer-promo.181c2a46.png"),
      path: "activity"
    }, {
      name: t('home.member'),
      bgImage: require("../assets/images/footer-member.a5a702e1.png"),
      path: "member"
    }]);
    const handleMainFund = computed(() => item => {
      if (item) {
        footerList.value.forEach((element, index) => {
          if (element.path == 'inviteFriends') {
            element.name = t('home.agent');
            element.path = 'agentRegister';
          }
        });
        console.log(footerList.value);
        return footerList.value;
      } else {
        return footerList.value;
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(handleMainFund)(_unref(store).state?.user?.type == 2), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [index != 2 ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "footer-homes",
          onClick: $event => _unref(handlenavGetTo)(item.path)
        }, [_createElementVNode("i", {
          class: "footer_icon home",
          style: _normalizeStyle({
            'background-image': 'url(' + item.bgImage + ')'
          })
        }, null, 4), _createElementVNode("span", null, _toDisplayString(item.name), 1)], 8, _hoisted_2)) : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "footer-deposit",
          onClick: _cache[0] || (_cache[0] = $event => _unref(handlenavGetTo)('voucherCenter'))
        }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
          class: _normalizeClass(_unref(store).state.user ? 'footer_icon Login' : 'footer_icon toLogin')
        }, null, 2), _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(store).state.user ? 'Deposit' : 'Login'), 1)])]))]);
      }), 128))]);
    };
  }
};